const CryptoCurrencies = {

    "BTC": {
        "currencyType": "coin",
        "currencyName": "Bitcoin",
        "currencyCode": "BTC",
        "currencySymbol": "BTC",
        "addressProcessor": "BTC",
        "scannerProcessor": "BTC",
        "extendsProcessor": "BTC",
        "prettyNumberProcessor": "BTC",
        "network": "mainnet",
        "decimals": 8,
        "currencyExplorerLink": "https://blockchair.com/bitcoin/address/",
        "currencyExplorerTxLink": "https://blockchair.com/bitcoin/transaction/",
        "addressPrefix": "1",
        "defaultPath": "m/44'/0'/0'/0/0"
    },
    "ETH": {
        "currencyType": "coin",
        "currencyName": "Ethereum",
        "currencyCode": "ETH",
        "currencySymbol": "ETH",
        "addressProcessor": "ETH",
        "scannerProcessor": "ETH",
        "extendsProcessor": "ETH",
        "prettyNumberProcessor": "ETH",
        "network": "mainnet",
        "decimals": 18,
        "currencyExplorerLink": "https://blockchair.com/ethereum/address/",
        "currencyExplorerTxLink": "https://blockchair.com/ethereum/transaction/"
    },
    "USDT": {
        "currencyType": "token",
        "currencyName": "Tether OMNI",
        "currencyCode": "USDT",
        "currencySymbol": "USDT",
        "extendsProcessor": "BTC",
        "addressCurrencyCode": "BTC",
        "scannerProcessor": "USDT",
        "prettyNumberProcessor": "USDT",
        "addressUiChecker": "BTC_LEGACY",
        "feesCurrencyCode": "BTC",
        "network": "mainnet",
        "decimals": 8,
        "tokenBlockchain": "BITCOIN",
        "currencyExplorerLink": "https://blockchair.com/bitcoin/address/",
        "currencyExplorerTxLink": "https://blockchair.com/bitcoin/transaction/"
    },
    "LTC": {
        "currencyType": "coin",
        "currencyName": "Litecoin",
        "currencyCode": "LTC",
        "currencySymbol": "LTC",
        "scannerProcessor": "LTC",
        "extendsProcessor": "BTC",
        "addressUiChecker": "BTC_BY_NETWORK",
        "rateUiScanner": "LTC",
        "network": "litecoin",
        "decimals": 8,
        "currencyExplorerLink": "https://blockchair.com/litecoin/address/",
        "currencyExplorerTxLink": "https://blockchair.com/litecoin/transaction/",
        "addressPrefix": "L",
        "defaultPath": "m/44'/2'/0'/0/0"
    },
    "ETH_USDT": {
        "currencyType": "token",
        "currencyName": "Tether ERC20",
        "currencyCode": "ETH_USDT",
        "currencySymbol": "USDT",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "USDT",
        "decimals": 6,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0xdac17f958d2ee523a2206206994597c13d831ec7",
        "currencyExplorerLink": "https://etherscan.io/token/0xdac17f958d2ee523a2206206994597c13d831ec7?a="
    },
    "ETH_UAX": {
        "currencyType": "token",
        "currencyName": "Crypto UAX",
        "currencyCode": "ETH_UAX",
        "currencySymbol": "UAX",
        "extendsProcessor": "ETH_TRUE_USD",
        "delegatedTransfer": true,
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "UAH",
        "decimals": 2,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x1Fc31488f28ac846588FFA201cDe0669168471bD",
        "currencyExplorerLink": "https://etherscan.io/token/0x1Fc31488f28ac846588FFA201cDe0669168471bD?a="
    },
    "TRX": {
        "currencyType": "coin",
        "currencyName": "Tron",
        "currencyCode": "TRX",
        "currencySymbol": "TRX",
        "addressProcessor": "TRX",
        "scannerProcessor": "TRX",
        "extendsProcessor": "TRX",
        "prettyNumberProcessor": "UNIFIED",
        "network": "mainnet",
        "decimals": 6,
        "currencyExplorerLink": "https://tronscan.org/#/address/",
        "currencyExplorerTxLink": "https://tronscan.org/#/transaction/"
    },
    "TRX_USDT": {
        "currencyType": "token",
        "transferProcessor": "TRX",
        "currencyName": "Tether TRC20",
        "currencyCode": "TRX_USDT",
        "currencyIcon": "TRX",
        "currencySymbol": "USDT",
        "ratesCurrencyCode": "USDT",
        "addressProcessor": "TRX",
        "scannerProcessor": "TRX",
        "prettyNumberProcessor": "UNIFIED",
        "addressCurrencyCode": "TRX",
        "addressUiChecker": "TRX",
        "feesCurrencyCode": "TRX",
        "network": "trx",
        "decimals": 6,
        "tokenBlockchain": "TRON",
        "tokenName": "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
        "currencyExplorerLink": "https://tronscan.org/#/address/",
        "currencyExplorerTxLink": "https://tronscan.org/#/transaction/"
    },
    "BNB": {
        "currencyType": "coin",
        "currencyName": "Binance Coin",
        "currencyCode": "BNB",
        "currencySymbol": "BNB",
        "addressProcessor": "BNB",
        "scannerProcessor": "BNB",
        "extendsProcessor": "BNB",
        "prettyNumberProcessor": "USDT",
        "network": "mainnet",
        "decimals": 8,
        "currencyExplorerLink": "https://explorer.binance.org/address/",
        "currencyExplorerTxLink": "https://explorer.binance.org/tx/"
    },
    "BNB_SMART": {
        "currencyType": "coin",
        "currencyName": "BNB Smart Chain",
        "currencyCode": "BNB_SMART",
        "currencySymbol": "BNB",
        "ratesCurrencyCode": "BNB",
        "addressCurrencyCode": "ETH",
        "addressProcessor": "ETH",
        "addressUiChecker": "ETH",
        "scannerProcessor": "ETH",
        "extendsProcessor": "ETH",
        "prettyNumberProcessor": "ETH",
        "transferProcessor": "BNB_SMART",
        "network": "mainnet",
        "decimals": 18,
        "currencyExplorerLink": "https://bscscan.com/address/",
        "currencyExplorerTxLink": "https://bscscan.com/tx/"
    },
    "ETH_TRUE_USD": {
        "currencyType": "token",
        "currencyName": "TrueUSD ERC20",
        "currencyCode": "ETH_TRUE_USD",
        "currencyIcon": "ETH",
        "currencySymbol": "TUSD",
        "addressProcessor": "ETH",
        "scannerProcessor": "ETH_ERC_20",
        "transferProcessor": "ETH_ERC_20",
        "prettyNumberProcessor": "ETH_ERC_20",
        "addressCurrencyCode": "ETH",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "TUSD",
        "feesCurrencyCode": "ETH",
        "network": "mainnet",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x0000000000085d4780B73119b644AE5ecd22b376",
        "currencyExplorerLink": "https://etherscan.io/token/0x0000000000085d4780B73119b644AE5ecd22b376?a=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "TRX_TUSD": {
        "currencyType": "token",
        "currencyName": "TrueUSD TRC20",
        "currencyCode": "TRX_TUSD",
        "currencySymbol": "TUSD",
        "extendsProcessor": "TRX_USDT",
        "addressUiChecker": "TRX",
        "ratesCurrencyCode": "TUSD",
        "network": "trx",
        "decimals": 18,
        "tokenBlockchain": "TRON",
        "tokenName": "TUpMhErZL2fhh4sVNULAbNKLokS4GjC1F4",
        "currencyExplorerLink": "https://tronscan.org/#/address/",
        "currencyExplorerTxLink": "https://tronscan.org/#/transaction/"
    },
    "ETH_BNB": {
        "currencyType": "token",
        "currencyName": "BNB ERC20",
        "currencyCode": "ETH_BNB",
        "currencySymbol": "BNB",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "BNB",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0xB8c77482e45F1F44dE1745F52C74426C631bDD52",
        "currencyExplorerLink": "https://etherscan.io/token/0xB8c77482e45F1F44dE1745F52C74426C631bDD52?a="
    },
    "ETH_USDC": {
        "currencyType": "token",
        "currencyName": "USDC ERC20",
        "currencyCode": "ETH_USDC",
        "currencySymbol": "USDC",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "USDC",
        "decimals": 6,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
        "currencyExplorerLink": "https://etherscan.io/token/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48?a=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "TRX_USDC": {
        "currencyType": "token",
        "currencyName": "USDC TRC20",
        "currencyCode": "TRX_USDC",
        "currencySymbol": "USDC",
        "extendsProcessor": "TRX_USDT",
        "addressUiChecker": "TRX",
        "ratesCurrencyCode": "USDC",
        "network": "trx",
        "decimals": 6,
        "tokenBlockchain": "TRON",
        "tokenName": "TEkxiTehnzSmSe2XqrBj4w32RUN966rdz8",
        "currencyExplorerLink": "https://tronscan.org/#/address/",
        "currencyExplorerTxLink": "https://tronscan.org/#/transaction/"
    },
    "ETH_PAX": {
        "currencyType": "token",
        "currencyName": "Pax Dollar",
        "currencyCode": "ETH_PAX",
        "currencySymbol": "USDP",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "USDP",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x8e870d67f660d95d5be530380d0ec0bd388289e1",
        "currencyExplorerLink": "https://etherscan.io/token/0x8e870d67f660d95d5be530380d0ec0bd388289e1?a="
    },
    "ETH_DAI": {
        "currencyType": "token",
        "currencyName": "Sai Stablecoin v1.0",
        "currencyCode": "ETH_DAI",
        "currencySymbol": "SAI",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "DAI",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359",
        "currencyExplorerLink": "https://etherscan.io/token/0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359?a="
    },
    "FIO": {
        "currencyType": "coin",
        "currencyName": "FIO",
        "currencyCode": "FIO",
        "currencySymbol": "FIO",
        "addressProcessor": "FIO",
        "scannerProcessor": "FIO",
        "extendsProcessor": "FIO",
        "prettyNumberProcessor": "UNIFIED",
        "network": "mainnet",
        "decimals": 9,
        "currencyExplorerLink": "https://fio.bloks.io/key/",
        "currencyExplorerTxLink": "https://fio.bloks.io/transaction/"
    },
    "ETH_ZRX": {
        "currencyType": "token",
        "currencyName": "0x",
        "currencyCode": "ETH_ZRX",
        "currencySymbol": "ZRX",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "ZRX",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0xe41d2489571d322189246dafa5ebde1f4699f498",
        "currencyExplorerLink": "https://etherscan.io/token/0xe41d2489571d322189246dafa5ebde1f4699f498?a=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "ETH_1INCH": {
        "currencyType": "token",
        "currencyName": "1inch ERC20",
        "currencyCode": "ETH_1INCH",
        "currencySymbol": "1INCH",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "1INCH",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x111111111117dc0aa78b770fa6a738034120c302",
        "currencyExplorerLink": "https://etherscan.io/token/0x111111111117dc0aa78b770fa6a738034120c302?a=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "BNB_SMART_1INCH": {
        "currencyType": "token",
        "currencyName": "1inch BEP20",
        "currencyCode": "BNB_SMART_1INCH",
        "currencySymbol": "1INCH",
        "ratesCurrencyCode": "1INCH",
        "extendsProcessor": "BNB_SMART_CAKE",
        "addressUiChecker": "ETH",
        "tokenAddress": "0x111111111117dc0aa78b770fa6a738034120c302",
        "decimals": 18,
        "tokenBlockchain": "BNB",
        "currencyExplorerLink": "https://bscscan.com/token/0x111111111117dc0aa78b770fa6a738034120c302?a="
    },
    "ETH_AAVE": {
        "currencyType": "token",
        "currencyName": "Aave ERC20",
        "currencyCode": "ETH_AAVE",
        "currencySymbol": "AAVE",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "AAVE",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9",
        "currencyExplorerLink": "https://etherscan.io/token/0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9?a=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "MATIC_AAVE": {
        "currencyType": "token",
        "currencyName": "AAVE Matic (Polygon)",
        "currencyCode": "MATIC_AAVE",
        "currencySymbol": "AAVE",
        "ratesCurrencyCode": "AAVE",
        "extendsProcessor": "MATIC_USDT",
        "addressUiChecker": "ETH",
        "tokenAddress": "0xd6df932a45c0f255f85145f286ea0b292b21c90b",
        "decimals": 18,
        "tokenBlockchain": "MATIC",
        "currencyExplorerLink": "https://polygonscan.com/token/0xd6df932a45c0f255f85145f286ea0b292b21c90b?a="
    },
    "FTM_AAVE": {
        "currencyType": "token",
        "currencyName": "AAVE Fantom",
        "currencyCode": "FTM_AAVE",
        "currencySymbol": "AAVE",
        "ratesCurrencyCode": "AAVE",
        "extendsProcessor": "FTM_USDC",
        "addressUiChecker": "ETH",
        "tokenAddress": "0x6a07A792ab2965C72a5B8088d3a069A7aC3a993B",
        "decimals": 18,
        "tokenBlockchain": "FTM",
        "currencyExplorerLink": "https://polygonscan.com/token/0x6a07A792ab2965C72a5B8088d3a069A7aC3a993B?a="
    },
    "ASH": {
        "currencyType": "coin",
        "currencyName": "Aeneas",
        "currencyCode": "ASH",
        "currencySymbol": "ASH",
        "ratesCurrencyCode": "ASH",
        "addressProcessor": "ASH",
        "scannerProcessor": "WAVES",
        "prettyNumberProcessor": "UNIFIED",
        "transferProcessor": "WAVES",
        "network": "mainnet",
        "decimals": 8,
        "currencyExplorerLink": "http://explorer.aeneas.id/address/",
        "currencyExplorerTxLink": "http://explorer.aeneas.id/tx/"
    },
    "AMB": {
        "currencyType": "coin",
        "currencyName": "Ambrosus",
        "currencyCode": "AMB",
        "currencySymbol": "AMB",
        "ratesCurrencyCode": "AMB",
        "addressProcessor": "ETH",
        "addressUiChecker": "ETH",
        "scannerProcessor": "ETH",
        "extendsProcessor": "ETH",
        "prettyNumberProcessor": "ETH",
        "transferProcessor": "ETC",
        "network": "mainnet",
        "decimals": 18,
        "currencyExplorerLink": "https://explorer.ambrosus.io/address/",
        "currencyExplorerTxLink": "https://explorer.ambrosus.io/tx/"
    },
    "BNB_SMART_ALPHA": {
        "currencyType": "token",
        "currencyName": "Alpha Finance BEP20",
        "currencyCode": "BNB_SMART_ALPHA",
        "currencySymbol": "ALPHA",
        "ratesCurrencyCode": "ALPHA",
        "extendsProcessor": "BNB_SMART_CAKE",
        "addressUiChecker": "ETH",
        "tokenAddress": "0xa1faa113cbe53436df28ff0aee54275c13b40975",
        "tokenBlockchain": "BNB",
        "currencyExplorerLink": "https://bscscan.com/token/0xa1faa113cbe53436df28ff0aee54275c13b40975?a="
    },
    "ETH_ALPHA": {
        "currencyType": "token",
        "currencyName": "Alpha Finance ERC20",
        "currencyCode": "ETH_ALPHA",
        "currencySymbol": "ALPHA",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "ALPHA",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0xa1faa113cbe53436df28ff0aee54275c13b40975",
        "currencyExplorerLink": "https://etherscan.io/token/0xa1faa113cbe53436df28ff0aee54275c13b40975?a=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "TRX_APE": {
        "currencyType": "token",
        "currencyName": "APENFT TRC20",
        "currencyCode": "TRX_APE",
        "currencySymbol": "APENFT",
        "extendsProcessor": "TRX_USDT",
        "addressUiChecker": "TRX",
        "ratesCurrencyCode": "NFT",
        "network": "trx",
        "decimals": 6,
        "tokenBlockchain": "TRON",
        "tokenName": "TFczxzPhnThNSqr5by8tvxsdCFRRz6cPNq",
        "currencyExplorerLink": "https://tronscan.org/#/address/",
        "currencyExplorerTxLink": "https://tronscan.org/#/transaction/"
    },
    "ETH_BADGER": {
        "currencyType": "token",
        "currencyName": "Badger DAO",
        "currencyCode": "ETH_BADGER",
        "currencySymbol": "BADGER",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "BADGER",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x3472a5a71965499acd81997a54bba8d852c6e53d",
        "currencyExplorerLink": "https://etherscan.io/token/0x3472a5a71965499acd81997a54bba8d852c6e53d?a=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "ETH_BAL": {
        "currencyType": "token",
        "currencyName": "Balancer",
        "currencyCode": "ETH_BAL",
        "currencySymbol": "BAL",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "BAL",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0xba100000625a3754423978a60c9317c58a424e3d",
        "currencyExplorerLink": "https://etherscan.io/token/0xba100000625a3754423978a60c9317c58a424e3d?a="
    },
    "ETH_BNT": {
        "currencyType": "token",
        "currencyName": "Bancor",
        "currencyCode": "ETH_BNT",
        "currencySymbol": "BNT",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "BNT",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c",
        "currencyExplorerLink": "https://etherscan.io/token/0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c?a="
    },
    "ETH_BAT": {
        "currencyType": "token",
        "currencyName": "Basic Attention Token ERC20",
        "currencyCode": "ETH_BAT",
        "currencySymbol": "BAT",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "BAT",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x0d8775f648430679a709e98d2b0cb6250d2887ef",
        "currencyExplorerLink": "https://etherscan.io/token/0x0d8775f648430679a709e98d2b0cb6250d2887ef?a=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "BNB_SMART_BAT": {
        "currencyType": "token",
        "currencyName": "Basic Attention Token BEP20",
        "currencyCode": "BNB_SMART_BAT",
        "currencySymbol": "BAT",
        "ratesCurrencyCode": "BAT",
        "extendsProcessor": "BNB_SMART_CAKE",
        "addressUiChecker": "ETH",
        "tokenAddress": "0x101d82428437127bf1608f699cd651e6abf9766e",
        "tokenBlockchain": "BNB",
        "currencyExplorerLink": "https://bscscan.com/token/0x101d82428437127bf1608f699cd651e6abf9766e?a="
    },
    "ETH_BUSD": {
        "currencyType": "token",
        "currencyName": "Binance USD ERC20",
        "currencyCode": "ETH_BUSD",
        "currencySymbol": "BUSD",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "BUSD",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x4fabb145d64652a948d72533023f6e7a623c7c53",
        "currencyExplorerLink": "https://etherscan.io/token/0x4fabb145d64652a948d72533023f6e7a623c7c53?a=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "BTC_TEST": {
        "currencyType": "coin",
        "currencyName": "Bitcoin (Testnet)",
        "currencyCode": "BTC_TEST",
        "currencySymbol": "BTC TEST",
        "scannerProcessor": "BTC_TEST",
        "ratesCurrencyCode": "SKIP",
        "extendsProcessor": "BTC",
        "network": "testnet",
        "decimals": 8,
        "currencyExplorerLink": "https://live.blockcypher.com/btc-testnet/address/",
        "currencyExplorerTxLink": "https://live.blockcypher.com/btc-testnet/tx/"
    },
    "BCH": {
        "currencyType": "coin",
        "currencyName": "Bitcoin Cash",
        "currencyCode": "BCH",
        "currencySymbol": "BCH",
        "addressProcessor": "BCH",
        "scannerProcessor": "BCH",
        "extendsProcessor": "BTC",
        "addressUiChecker": "BTC_BY_NETWORK",
        "rateUiScanner": "BCH",
        "network": "bitcoincash",
        "decimals": 8,
        "currencyExplorerLink": "https://blockchair.com/bitcoin-cash/address/",
        "currencyExplorerTxLink": "https://blockchair.com/bitcoin-cash/transaction/"
    },
    "BTG": {
        "currencyType": "coin",
        "currencyName": "Bitcoin Gold",
        "currencyCode": "BTG",
        "currencySymbol": "BTG",
        "scannerProcessor": "BTG",
        "extendsProcessor": "BTC",
        "addressUiChecker": "BTC_BY_NETWORK",
        "rateUiScanner": "BTG",
        "network": "bitcoingold",
        "decimals": 8,
        "currencyExplorerLink": "https://explorer.bitcoingold.org/insight/address/",
        "currencyExplorerTxLink": "https://explorer.bitcoingold.org/insight/tx/"
    },
    "BSV": {
        "currencyType": "coin",
        "currencyName": "BitcoinSV",
        "currencyCode": "BSV",
        "currencySymbol": "BSV",
        "scannerProcessor": "BSV",
        "extendsProcessor": "BTC",
        "addressUiChecker": "BTC_BY_NETWORK",
        "rateUiScanner": "BSV",
        "network": "bitcoinsv",
        "decimals": 8,
        "currencyExplorerLink": "https://blockchair.com/bitcoin-sv/address/",
        "currencyExplorerTxLink": "https://blockchair.com/bitcoin-sv/transaction/"
    },
    "TRX_BTT_NEW": {
        "currencyType": "token",
        "currencyName": "BitTorrent New",
        "currencyCode": "TRX_BTT_NEW",
        "currencySymbol": "BTT",
        "extendsProcessor": "TRX_USDT",
        "addressUiChecker": "TRX",
        "ratesCurrencyCode": "BTT_NEW",
        "network": "trx",
        "decimals": 18,
        "tokenBlockchain": "TRON",
        "tokenName": "TAFjULxiVgT4qWk6UZwjqwZXTSaGaqnVp4",
        "currencyExplorerLink": "https://tronscan.org/#/address/",
        "currencyExplorerTxLink": "https://tronscan.org/#/transaction/"
    },
    "TRX_BTT": {
        "currencyType": "token",
        "currencyName": "BitTorrent Old",
        "currencyCode": "TRX_BTT",
        "currencySymbol": "BTT",
        "extendsProcessor": "TRX_USDT",
        "addressUiChecker": "TRX",
        "ratesCurrencyCode": "BTT",
        "network": "trx",
        "skipParentBalanceCheck": true,
        "decimals": 6,
        "tokenBlockchain": "TRON",
        "tokenName": "1002000",
        "currencyExplorerLink": "https://tronscan.org/#/address/",
        "currencyExplorerTxLink": "https://tronscan.org/#/transaction/"
    },
    "TRX_WBTT": {
        "currencyType": "token",
        "currencyName": "BitTorrent TRC20",
        "currencyCode": "TRX_WBTT",
        "currencySymbol": "WBTT",
        "extendsProcessor": "TRX_USDT",
        "addressUiChecker": "TRX",
        "ratesCurrencyCode": "BTT",
        "network": "trx",
        "decimals": 6,
        "tokenBlockchain": "TRON",
        "tokenName": "TKfjV9RNKJJCqPvBtK8L7Knykh7DNWvnYt",
        "currencyExplorerLink": "https://tronscan.org/#/address/",
        "currencyExplorerTxLink": "https://tronscan.org/#/transaction/"
    },
    "BNB_SMART_BTT": {
        "currencyType": "token",
        "currencyName": "BTT BEP20",
        "currencyCode": "BNB_SMART_BTT",
        "currencySymbol": "BTT",
        "ratesCurrencyCode": "BTT",
        "extendsProcessor": "BNB_SMART_CAKE",
        "addressUiChecker": "ETH",
        "tokenAddress": "0x8595f9da7b868b1822194faed312235e43007b49",
        "tokenBlockchain": "BNB",
        "currencyExplorerLink": "https://bscscan.com/token/0x8595f9da7b868b1822194faed312235e43007b49?a="
    },
    "ETH_BTT": {
        "currencyType": "token",
        "currencyName": "BTT ERC20",
        "currencyCode": "ETH_BTT",
        "currencySymbol": "BTT",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "BTT",
        "decimals": 6,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0xe83cccfabd4ed148903bf36d4283ee7c8b3494d1",
        "currencyExplorerLink": "https://etherscan.io/token/0xe83cccfabd4ed148903bf36d4283ee7c8b3494d1?a="
    },
    "BNB_SMART_BTC": {
        "currencyType": "token",
        "currencyName": "BTC BEP20",
        "currencyCode": "BNB_SMART_BTC",
        "currencySymbol": "BTC",
        "ratesCurrencyCode": "BTC",
        "extendsProcessor": "BNB_SMART_CAKE",
        "addressUiChecker": "ETH",
        "tokenAddress": "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
        "tokenBlockchain": "BNB",
        "currencyExplorerLink": "https://bscscan.com/token/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c?a="
    },
    "TRX_BTC": {
        "currencyType": "token",
        "currencyName": "BTC TRC20",
        "currencyCode": "TRX_BTC",
        "currencySymbol": "BTC",
        "extendsProcessor": "TRX_USDT",
        "addressUiChecker": "TRX",
        "ratesCurrencyCode": "BTC",
        "network": "trx",
        "decimals": 8,
        "tokenBlockchain": "TRON",
        "tokenName": "TN3W4H6rK2ce4vX9YnFQHwKENnHjoxb3m9",
        "currencyExplorerLink": "https://tronscan.org/#/address/",
        "currencyExplorerTxLink": "https://tronscan.org/#/transaction/"
    },
    "FTM_BTC": {
        "currencyType": "token",
        "currencyName": "BTC Fantom",
        "currencyCode": "FTM_BTC",
        "currencySymbol": "BTC",
        "ratesCurrencyCode": "BTC",
        "extendsProcessor": "FTM_USDC",
        "addressUiChecker": "ETH",
        "tokenAddress": "0x321162Cd933E2Be498Cd2267a90534A804051b11",
        "decimals": 8,
        "tokenBlockchain": "FTM",
        "currencyExplorerLink": "https://polygonscan.com/token/0x321162Cd933E2Be498Cd2267a90534A804051b11?a="
    },
    "FTM_BOO": {
        "currencyType": "token",
        "currencyName": "SpookyToken",
        "currencyCode": "FTM_BOO",
        "currencySymbol": "BOO",
        "ratesCurrencyCode": "BOO",
        "extendsProcessor": "FTM_USDC",
        "addressUiChecker": "ETH",
        "tokenAddress": "0x841fad6eae12c286d1fd18d1d525dffa75c7effe",
        "decimals": 18,
        "tokenBlockchain": "FTM",
        "currencyExplorerLink": "https://polygonscan.com/token/0x841fad6eae12c286d1fd18d1d525dffa75c7effe?a="
    },
    "BNB_SMART_ADA": {
        "currencyType": "token",
        "currencyName": "Cardano BEP20",
        "currencyCode": "BNB_SMART_ADA",
        "currencySymbol": "ADA",
        "ratesCurrencyCode": "ADA",
        "extendsProcessor": "BNB_SMART_CAKE",
        "addressUiChecker": "ETH",
        "tokenAddress": "0x3ee2200efb3400fabb9aacf31297cbdd1d435d47",
        "tokenBlockchain": "BNB",
        "currencyExplorerLink": "https://bscscan.com/token/0x3ee2200efb3400fabb9aacf31297cbdd1d435d47?a="
    },
    "BNB_SMART_LINK": {
        "currencyType": "token",
        "currencyName": "Chainlink BEP20",
        "currencyCode": "BNB_SMART_LINK",
        "currencySymbol": "LINK",
        "ratesCurrencyCode": "LINK",
        "extendsProcessor": "BNB_SMART_CAKE",
        "addressUiChecker": "ETH",
        "tokenAddress": "0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd",
        "tokenBlockchain": "BNB",
        "currencyExplorerLink": "https://bscscan.com/token/0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd?a="
    },
    "ETH_LINK": {
        "currencyType": "token",
        "currencyName": "Chainlink ERC20",
        "currencyCode": "ETH_LINK",
        "currencySymbol": "LINK",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "LINK",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x514910771af9ca656af840dff83e8264ecf986ca",
        "currencyExplorerLink": "https://etherscan.io/token/0x514910771af9ca656af840dff83e8264ecf986ca?a=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "FTM_LINK": {
        "currencyType": "token",
        "currencyName": "Chainlink Fantom",
        "currencyCode": "FTM_LINK",
        "currencySymbol": "LINK",
        "ratesCurrencyCode": "LINK",
        "extendsProcessor": "FTM_USDC",
        "addressUiChecker": "ETH",
        "tokenAddress": "0xb3654dc3d10ea7645f8319668e8f54d2574fbdc8",
        "decimals": 18,
        "tokenBlockchain": "FTM",
        "currencyExplorerLink": "https://polygonscan.com/token/0xb3654dc3d10ea7645f8319668e8f54d2574fbdc8?a="
    },
    "ETH_NOW": {
        "currencyType": "token",
        "currencyName": "ChangeNOW",
        "currencyCode": "ETH_NOW",
        "currencySymbol": "NOW",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "NOW",
        "decimals": 8,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0xe9a95d175a5f4c9369f3b74222402eb1b837693b",
        "currencyExplorerLink": "https://etherscan.io/token/0xe9a95d175a5f4c9369f3b74222402eb1b837693b?a="
    },
    "ETH_CHZ": {
        "currencyType": "token",
        "currencyName": "Chiliz",
        "currencyCode": "ETH_CHZ",
        "currencySymbol": "CHZ",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "CHZ",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x3506424f91fd33084466f402d5d97f05f8e3b4af",
        "currencyExplorerLink": "https://etherscan.io/tokEN/0X3506424F91FD33084466F402D5D97F05F8E3B4AF?A=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "ETH_COMP": {
        "currencyType": "token",
        "currencyName": "Compound",
        "currencyCode": "ETH_COMP",
        "currencySymbol": "COMP",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "COMP",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0xc00e94cb662c3520282e6f5717214004a7f26888",
        "currencyExplorerLink": "https://etherscan.io/token/0xc00e94cb662c3520282e6f5717214004a7f26888?a="
    },
    "SOL_COPE": {
        "currencyType": "token",
        "currencyName": "COPE",
        "currencyCode": "SOL_COPE",
        "currencySymbol": "COPE",
        "extendsProcessor": "SOL_RAY",
        "addressUiChecker": "SOL",
        "ratesCurrencyCode": "COPE",
        "decimals": 6,
        "tokenBlockchain": "SOLANA",
        "tokenAddress": "8HGyAAB1yoM1ttS7pXjHMa3dukTFGQggnFFH3hJZgzQh",
        "currencyExplorerLink": "https://explorer.solana.com/address/",
        "currencyExplorerTxLink": "https://explorer.solana.com/tx/"
    },
    "ETH_CRO": {
        "currencyType": "token",
        "currencyName": "Crypto.com Coin",
        "currencyCode": "ETH_CRO",
        "currencySymbol": "CRO",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "CRO",
        "decimals": 8,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b",
        "currencyExplorerLink": "https://etherscan.io/token/0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b?a=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "ETH_SOUL": {
        "currencyType": "token",
        "currencyName": "CryptoSoul",
        "currencyCode": "ETH_SOUL",
        "currencySymbol": "SOUL",
        "extendsProcessor": "ETH_TRUE_USD",
        "scannerProcessor": "ETH_SOUL",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "SOUL",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0xbb1f24c0c1554b9990222f036b0aad6ee4caec29",
        "currencyExplorerLink": "https://etherscan.io/token/0xbb1f24c0c1554b9990222f036b0aad6ee4caec29?a="
    },
    "ETH_CRV": {
        "currencyType": "token",
        "currencyName": "Curve DAO Token",
        "currencyCode": "ETH_CRV",
        "currencySymbol": "CRV",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "CRV",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0xd533a949740bb3306d119cc777fa900ba034cd52",
        "currencyExplorerLink": "https://etherscan.io/token/0xd533a949740bb3306d119cc777fa900ba034cd52?a=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "ETH_DAIM": {
        "currencyType": "token",
        "currencyName": "Dai Stablecoin",
        "currencyCode": "ETH_DAIM",
        "currencySymbol": "DAI",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "DAI",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x6b175474e89094c44da98b954eedeac495271d0f",
        "currencyExplorerLink": "https://etherscan.io/token/0x6b175474e89094c44da98b954eedeac495271d0f?a="
    },
    "DOGE": {
        "currencyType": "coin",
        "currencyName": "Dogecoin",
        "currencyCode": "DOGE",
        "currencySymbol": "DOGE",
        "extendsProcessor": "BTC",
        "scannerProcessor": "DOGE",
        "addressUiChecker": "BTC_BY_NETWORK",
        "rateUiScanner": "DOGE",
        "network": "dogecoin",
        "decimals": 8,
        "currencyExplorerLink": "https://blockchair.com/dogecoin/address/",
        "currencyExplorerTxLink": "https://blockchair.com/dogecoin/transaction/"
    },
    "BNB_SMART_DOGE": {
        "currencyType": "token",
        "currencyName": "DOGE BEP20",
        "currencyCode": "BNB_SMART_DOGE",
        "currencySymbol": "DOGE",
        "ratesCurrencyCode": "DOGE",
        "extendsProcessor": "BNB_SMART_CAKE",
        "addressUiChecker": "ETH",
        "decimals": 8,
        "tokenAddress": "0xba2ae424d960c26247dd6c32edc70b295c744c43",
        "tokenBlockchain": "BNB",
        "currencyExplorerLink": "https://bscscan.com/token/0xba2ae424d960c26247dd6c32edc70b295c744c43?a="
    },
    "TRX_DOGE": {
        "currencyType": "token",
        "currencyName": "DOGE TRC20",
        "currencyCode": "TRX_DOGE",
        "currencySymbol": "DOGE",
        "extendsProcessor": "TRX_USDT",
        "addressUiChecker": "TRX",
        "ratesCurrencyCode": "DOGE",
        "network": "trx",
        "decimals": 8,
        "tokenBlockchain": "TRON",
        "tokenName": "THbVQp8kMjStKNnf2iCY6NEzThKMK5aBHg",
        "currencyExplorerLink": "https://tronscan.org/#/address/",
        "currencyExplorerTxLink": "https://tronscan.org/#/transaction/"
    },
    "ETH_ENJ": {
        "currencyType": "token",
        "currencyName": "EnjinCoin",
        "currencyCode": "ETH_ENJ",
        "currencySymbol": "ENJ",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "ENJ",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c",
        "currencyExplorerLink": "https://etherscan.io/token/0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c?a=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "BNB_SMART_ETH": {
        "currencyType": "token",
        "currencyName": "ETH BEP20",
        "currencyCode": "BNB_SMART_ETH",
        "currencySymbol": "ETH",
        "ratesCurrencyCode": "ETH",
        "extendsProcessor": "BNB_SMART_CAKE",
        "addressUiChecker": "ETH",
        "tokenAddress": "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
        "tokenBlockchain": "BNB",
        "currencyExplorerLink": "https://bscscan.com/token/0x2170ed0880ac9a755fd29b2688956bd959f933f8?a="
    },
    "MATIC_ETH": {
        "currencyType": "token",
        "currencyName": "ETH Matic (Polygon)",
        "currencyCode": "MATIC_ETH",
        "currencySymbol": "ETH",
        "ratesCurrencyCode": "ETH",
        "extendsProcessor": "MATIC_USDT",
        "addressUiChecker": "ETH",
        "tokenAddress": "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
        "decimals": 18,
        "tokenBlockchain": "MATIC",
        "currencyExplorerLink": "https://polygonscan.com/token/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619?a="
    },
    "TRX_ETH": {
        "currencyType": "token",
        "currencyName": "ETH TRC20",
        "currencyCode": "TRX_ETH",
        "currencySymbol": "ETH",
        "extendsProcessor": "TRX_USDT",
        "addressUiChecker": "TRX",
        "ratesCurrencyCode": "ETH",
        "network": "trx",
        "decimals": 18,
        "tokenBlockchain": "TRON",
        "tokenName": "THb4CqiFdwNHsWsQCs4JhzwjMWys4aqCbF",
        "currencyExplorerLink": "https://tronscan.org/#/address/",
        "currencyExplorerTxLink": "https://tronscan.org/#/transaction/"
    },
    "ETC": {
        "currencyType": "coin",
        "currencyName": "Ethereum Classic",
        "currencyCode": "ETC",
        "currencySymbol": "ETC",
        "ratesCurrencyCode": "ETC",
        "addressCurrencyCode": "ETC",
        "addressProcessor": "ETH",
        "addressUiChecker": "ETH",
        "scannerProcessor": "ETH",
        "extendsProcessor": "ETH",
        "prettyNumberProcessor": "ETH",
        "transferProcessor": "ETC",
        "network": "mainnet",
        "decimals": 18,
        "currencyExplorerLink": "https://blockscout.com/etc/mainnet/address/",
        "currencyExplorerTxLink": "https://blockscout.com/etc/mainnet/tx/"
    },
    "ETH_ROPSTEN": {
        "currencyType": "coin",
        "currencyName": "Ethereum Ropsten",
        "currencyCode": "ETH_ROPSTEN",
        "currencySymbol": "ETH",
        "currencyIcon": "ETH",
        "ratesCurrencyCode": "SKIP",
        "extendsProcessor": "ETH",
        "transferProcessor": "ETH",
        "network": "ropsten",
        "decimals": 18,
        "currencyExplorerLink": "https://ropsten.etherscan.io/address/",
        "currencyExplorerTxLink": "https://ropsten.etherscan.io/tx/"
    },
    "ETH_RINKEBY": {
        "currencyType": "coin",
        "currencyName": "Ethereum Rinkeby",
        "currencyCode": "ETH_RINKEBY",
        "currencySymbol": "ETH",
        "currencyIcon": "ETH",
        "ratesCurrencyCode": "SKIP",
        "extendsProcessor": "ETH",
        "transferProcessor": "ETH",
        "network": "rinkeby",
        "decimals": 18,
        "currencyExplorerLink": "https://rinkeby.etherscan.io/address/",
        "currencyExplorerTxLink": "https://rinkeby.etherscan.io/tx/"
    },
    "ETH_LEND": {
        "currencyType": "token",
        "currencyName": "EthLend",
        "currencyCode": "ETH_LEND",
        "currencySymbol": "LEND",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "LEND",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x80fb784b7ed66730e8b1dbd9820afd29931aab03",
        "currencyExplorerLink": "https://etherscan.io/token/0x80fb784b7ed66730e8b1dbd9820afd29931aab03?a="
    },
    "SOL_FIDA": {
        "currencyType": "token",
        "currencyName": "FIDA",
        "currencyCode": "SOL_FIDA",
        "currencySymbol": "FIDA",
        "extendsProcessor": "SOL_RAY",
        "addressUiChecker": "SOL",
        "ratesCurrencyCode": "FIDA",
        "decimals": 6,
        "tokenBlockchain": "SOLANA",
        "tokenAddress": "EchesyfXePKdLtoiZSL8pBe8Myagyy8ZRqsACNCFGnvp",
        "currencyExplorerLink": "https://explorer.solana.com/address/",
        "currencyExplorerTxLink": "https://explorer.solana.com/tx/"
    },
    "FTM": {
        "currencyType": "coin",
        "currencyName": "Fantom Network",
        "currencyCode": "FTM",
        "currencySymbol": "FTM",
        "ratesCurrencyCode": "FTM",
        "addressProcessor": "ETH",
        "addressUiChecker": "ETH",
        "scannerProcessor": "ETH",
        "extendsProcessor": "ETH",
        "prettyNumberProcessor": "ETH",
        "transferProcessor": "ETC",
        "network": "mainnet",
        "decimals": 18,
        "currencyExplorerLink": "https://ftmscan.com/address/",
        "currencyExplorerTxLink": "https://ftmscan.com/tx/"
    },
    "ETH_FTM": {
        "currencyType": "token",
        "currencyName": "FTM ERC20",
        "currencyCode": "ETH_FTM",
        "currencySymbol": "FTM",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "FTM",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x4e15361fd6b4bb609fa63c81a2be19d873717870",
        "currencyExplorerLink": "https://etherscan.io/token/0x4e15361fd6b4bb609fa63c81a2be19d873717870?a=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "BNB_SMART_FTM": {
        "currencyType": "token",
        "currencyName": "FTM BEP20",
        "currencyCode": "BNB_SMART_FTM",
        "currencySymbol": "FTM",
        "ratesCurrencyCode": "FTM",
        "extendsProcessor": "BNB_SMART_CAKE",
        "addressUiChecker": "ETH",
        "tokenAddress": "0xad29abb318791d579433d831ed122afeaf29dcfe",
        "tokenBlockchain": "BNB",
        "currencyExplorerLink": "https://bscscan.com/token/0xad29abb318791d579433d831ed122afeaf29dcfe?a="
    },
    "MATIC_FTM": {
        "currencyType": "token",
        "currencyName": "FTM Polygon",
        "currencyCode": "MATIC_FTM",
        "currencySymbol": "FTM",
        "ratesCurrencyCode": "MATIC",
        "extendsProcessor": "MATIC_USDT",
        "addressUiChecker": "ETH",
        "tokenAddress": "0xc9c1c1c20b3658f8787cc2fd702267791f224ce1",
        "decimals": 18,
        "tokenBlockchain": "MATIC",
        "currencyExplorerLink": "https://polygonscan.com/token/0xc9c1c1c20b3658f8787cc2fd702267791f224ce1?a="
    },
    "ETH_FTT": {
        "currencyType": "token",
        "currencyName": "FTX Token",
        "currencyCode": "ETH_FTT",
        "currencySymbol": "FTT",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "FTT",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9",
        "currencyExplorerLink": "https://etherscan.io/token/0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9?a=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "ETH_GRT": {
        "currencyType": "token",
        "currencyName": "Graph Token",
        "currencyCode": "ETH_GRT",
        "currencySymbol": "GRT",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "GRT",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0xc944e90c64b2c07662a292be6244bdf05cda44a7",
        "currencyExplorerLink": "https://etherscan.io/token/0xc944e90c64b2c07662a292be6244bdf05cda44a7?a=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "ETH_ONE": {
        "currencyType": "token",
        "currencyName": "Harmony",
        "currencyCode": "ETH_ONE",
        "currencySymbol": "ONE",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "ONE",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x799a4202c12ca952cb311598a024c80ed371a41e",
        "currencyExplorerLink": "https://etherscan.io/token/0x799a4202c12ca952cb311598a024c80ed371a41e?a="
    },
    "ETH_HUOBI": {
        "currencyType": "token",
        "currencyName": "Huobi Token",
        "currencyCode": "ETH_HUOBI",
        "currencySymbol": "HT",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "HT",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x6f259637dcd74c767781e37bc6133cd6a68aa161",
        "currencyExplorerLink": "https://etherscan.io/token/0x6f259637dcd74c767781e37bc6133cd6a68aa161?a=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "TRX_JST": {
        "currencyType": "token",
        "currencyName": "JUST",
        "currencyCode": "TRX_JST",
        "currencySymbol": "JST",
        "extendsProcessor": "TRX_USDT",
        "addressUiChecker": "TRX",
        "ratesCurrencyCode": "JST",
        "network": "trx",
        "decimals": 18,
        "tokenBlockchain": "TRON",
        "tokenName": "TCFLL5dx5ZJdKnWuesXxi1VPwjLVmWZZy9",
        "currencyExplorerLink": "https://tronscan.org/#/address/",
        "currencyExplorerTxLink": "https://tronscan.org/#/transaction/"
    },
    "TRX_USDJ": {
        "currencyType": "token",
        "currencyName": "JUST Stablecoin",
        "currencyCode": "TRX_USDJ",
        "currencySymbol": "USDJ",
        "extendsProcessor": "TRX_USDT",
        "addressUiChecker": "TRX",
        "ratesCurrencyCode": "USDJ",
        "network": "trx",
        "decimals": 18,
        "tokenBlockchain": "TRON",
        "tokenName": "TMwFHYXLJaRUPeW6421aqXL4ZEzPRFGkGT",
        "currencyExplorerLink": "https://tronscan.org/#/address/",
        "currencyExplorerTxLink": "https://tronscan.org/#/transaction/"
    },
    "SOL_KIN": {
        "currencyType": "token",
        "currencyName": "KIN",
        "currencyCode": "SOL_KIN",
        "currencySymbol": "KIN",
        "extendsProcessor": "SOL_RAY",
        "addressUiChecker": "SOL",
        "ratesCurrencyCode": "KIN",
        "decimals": 5,
        "tokenBlockchain": "SOLANA",
        "tokenAddress": "kinXdEcpDQeHPEuQnqmUgtYykqKGVFq6CeVX5iAHJq6",
        "currencyExplorerLink": "https://explorer.solana.com/address/",
        "currencyExplorerTxLink": "https://explorer.solana.com/tx/"
    },
    "ETH_KNC": {
        "currencyType": "token",
        "currencyName": "KyberNetwork (Old)",
        "currencyCode": "ETH_KNC",
        "currencySymbol": "KNC",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "KNC",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0xdd974d5c2e2928dea5f71b9825b8b646686bd200",
        "currencyExplorerLink": "https://etherscan.io/token/0xdd974d5c2e2928dea5f71b9825b8b646686bd200?a="
    },
    "ETH_KNC_NEW": {
        "currencyType": "token",
        "currencyName": "KyberNetwork",
        "currencyCode": "ETH_KNC_NEW",
        "currencySymbol": "KNC",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "KNC",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0xdefa4e8a7bcba345f687a2f1456f5edd9ce97202",
        "currencyExplorerLink": "https://etherscan.io/token/0xdefa4e8a7bcba345f687a2f1456f5edd9ce97202?a="
    },
    "BNB_SMART_LTC": {
        "currencyType": "token",
        "currencyName": "LTC BEP20",
        "currencyCode": "BNB_SMART_LTC",
        "currencySymbol": "LTC",
        "ratesCurrencyCode": "LTC",
        "extendsProcessor": "BNB_SMART_CAKE",
        "addressUiChecker": "ETH",
        "tokenAddress": "0x4338665cbb7b2485a8855a139b75d5e34ab0db94",
        "tokenBlockchain": "BNB",
        "currencyExplorerLink": "https://bscscan.com/token/0x4338665cbb7b2485a8855a139b75d5e34ab0db94?a="
    },
    "ETH_MKR": {
        "currencyType": "token",
        "currencyName": "Maker",
        "currencyCode": "ETH_MKR",
        "currencySymbol": "MKR",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "MKR",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2",
        "currencyExplorerLink": "https://etherscan.io/token/0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2?a="
    },
    "SOL_MNGO": {
        "currencyType": "token",
        "currencyName": "MANGO Markets",
        "currencyCode": "SOL_MNGO",
        "currencySymbol": "MNGO",
        "extendsProcessor": "SOL_RAY",
        "addressUiChecker": "SOL",
        "ratesCurrencyCode": "MNGO",
        "decimals": 6,
        "tokenBlockchain": "SOLANA",
        "tokenAddress": "MangoCzJ36AjZyKwVj3VnYU4GTonjfVEnJmvvWaxLac",
        "currencyExplorerLink": "https://explorer.solana.com/address/",
        "currencyExplorerTxLink": "https://explorer.solana.com/tx/"
    },
    "SOL_MAPS": {
        "currencyType": "token",
        "currencyName": "MAPS",
        "currencyCode": "SOL_MAPS",
        "currencySymbol": "MAPS",
        "extendsProcessor": "SOL_RAY",
        "addressUiChecker": "SOL",
        "ratesCurrencyCode": "MAPS",
        "decimals": 6,
        "tokenBlockchain": "SOLANA",
        "tokenAddress": "MAPS41MDahZ9QdKXhVa4dWB9RuyfV4XqhyAZ8XcYepb",
        "currencyExplorerLink": "https://explorer.solana.com/address/",
        "currencyExplorerTxLink": "https://explorer.solana.com/tx/"
    },
    "SOL_MEDIA": {
        "currencyType": "token",
        "currencyName": "Media Network",
        "currencyCode": "SOL_MEDIA",
        "currencySymbol": "MEDIA",
        "extendsProcessor": "SOL_RAY",
        "addressUiChecker": "SOL",
        "ratesCurrencyCode": "MEDIA",
        "decimals": 6,
        "tokenBlockchain": "SOLANA",
        "tokenAddress": "ETAtLmCmsoiEEKfNrHKJ2kYy3MoABhU6NQvpSfij5tDs",
        "currencyExplorerLink": "https://explorer.solana.com/address/",
        "currencyExplorerTxLink": "https://explorer.solana.com/tx/"
    },
    "SOL_MER": {
        "currencyType": "token",
        "currencyName": "Mercurial Finance",
        "currencyCode": "SOL_MER",
        "currencySymbol": "MER",
        "extendsProcessor": "SOL_RAY",
        "addressUiChecker": "SOL",
        "ratesCurrencyCode": "MER",
        "decimals": 6,
        "tokenBlockchain": "SOLANA",
        "tokenAddress": "MERt85fc5boKw3BW1eYdxonEuJNvXbiMbs6hvheau5K",
        "currencyExplorerLink": "https://explorer.solana.com/address/",
        "currencyExplorerTxLink": "https://explorer.solana.com/tx/"
    },
    "XMR": {
        "currencyType": "coin",
        "currencyName": "Monero",
        "currencyCode": "XMR",
        "currencySymbol": "XMR",
        "addressProcessor": "XMR",
        "scannerProcessor": "XMR",
        "extendsProcessor": "XMR",
        "prettyNumberProcessor": "UNIFIED",
        "network": "mainnet",
        "decimals": 12,
        "currencyExplorerLink": "https://xmrchain.net/search?value=",
        "currencyExplorerTxLink": "https://blockchair.com/monero/transaction/"
    },
    "ETH_NEXO": {
        "currencyType": "token",
        "currencyName": "Nexo",
        "currencyCode": "ETH_NEXO",
        "currencySymbol": "NEXO",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "NEXO",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0xb62132e35a6c13ee1ee0f84dc5d40bad8d815206",
        "currencyExplorerLink": "https://etherscan.io/token/0xb62132e35a6c13ee1ee0f84dc5d40bad8d815206?a=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "ETH_OKB": {
        "currencyType": "token",
        "currencyName": "OKB",
        "currencyCode": "ETH_OKB",
        "currencySymbol": "OKB",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "OKB",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x75231f58b43240c9718dd58b4967c5114342a86c",
        "currencyExplorerLink": "https://etherscan.io/token/0x75231f58b43240c9718dd58b4967c5114342a86c?a="
    },
    "ETH_OMG": {
        "currencyType": "token",
        "currencyName": "OMG Network",
        "currencyCode": "ETH_OMG",
        "currencySymbol": "OMG",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "OMG",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0xd26114cd6ee289accf82350c8d8487fedb8a0c07",
        "currencyExplorerLink": "https://etherscan.io/token/0xd26114cd6ee289accf82350c8d8487fedb8a0c07?a=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "OPTIMISM": {
        "currencyType": "coin",
        "currencyName": "Optimistic Ethereum",
        "currencyCode": "OPTIMISM",
        "currencySymbol": "ETH",
        "ratesCurrencyCode": "ETH",
        "addressProcessor": "ETH",
        "addressUiChecker": "ETH",
        "scannerProcessor": "ETH",
        "extendsProcessor": "ETH",
        "prettyNumberProcessor": "ETH",
        "transferProcessor": "ETC",
        "network": "mainnet",
        "decimals": 18,
        "currencyExplorerLink": "https://optimistic.etherscan.io/address/",
        "currencyExplorerTxLink": "https://optimistic.etherscan.io/tx/"
    },
    "SOL_ORCA": {
        "currencyType": "token",
        "currencyName": "ORCA",
        "currencyCode": "SOL_ORCA",
        "currencySymbol": "ORCA",
        "extendsProcessor": "SOL_RAY",
        "addressUiChecker": "SOL",
        "ratesCurrencyCode": "ORCA",
        "decimals": 6,
        "tokenBlockchain": "SOLANA",
        "tokenAddress": "orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE",
        "currencyExplorerLink": "https://explorer.solana.com/address/",
        "currencyExplorerTxLink": "https://explorer.solana.com/tx/"
    },
    "SOL_OXY": {
        "currencyType": "token",
        "currencyName": "Oxygen Protocol",
        "currencyCode": "SOL_OXY",
        "currencySymbol": "OXY",
        "extendsProcessor": "SOL_RAY",
        "addressUiChecker": "SOL",
        "ratesCurrencyCode": "OXY",
        "decimals": 6,
        "tokenBlockchain": "SOLANA",
        "tokenAddress": "z3dn17yLaGMKffVogeFHQ9zWVcXgqgf3PQnDsNs2g6M",
        "currencyExplorerLink": "https://explorer.solana.com/address/",
        "currencyExplorerTxLink": "https://explorer.solana.com/tx/"
    },
    "SOL_PAI": {
        "currencyType": "token",
        "currencyName": "PAI (Parrot)",
        "currencyCode": "SOL_PAI",
        "currencySymbol": "PAI",
        "extendsProcessor": "SOL_RAY",
        "addressUiChecker": "SOL",
        "ratesCurrencyCode": "PAI",
        "decimals": 6,
        "tokenBlockchain": "SOLANA",
        "tokenAddress": "Ea5SjE2Y6yvCeW5dYTn7PYMuW5ikXkvbGdcmSnXeaLjS",
        "currencyExplorerLink": "https://explorer.solana.com/address/",
        "currencyExplorerTxLink": "https://explorer.solana.com/tx/"
    },
    "BNB_SMART_CAKE": {
        "currencyType": "token",
        "currencyName": "PancakeSwap Token",
        "currencyCode": "BNB_SMART_CAKE",
        "currencyIcon": "BNB",
        "currencySymbol": "CAKE",
        "addressProcessor": "ETH",
        "scannerProcessor": "ETH_ERC_20",
        "transferProcessor": "BNB_SMART_20",
        "prettyNumberProcessor": "ETH_ERC_20",
        "addressCurrencyCode": "ETH",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "CAKE",
        "feesCurrencyCode": "BNB_SMART",
        "network": "mainnet",
        "decimals": 18,
        "tokenBlockchain": "BNB",
        "tokenAddress": "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
        "currencyExplorerLink": "https://bscscan.com/token/0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82?a=",
        "currencyExplorerTxLink": "https://bscscan.com/tx/"
    },
    "ETH_MATIC": {
        "currencyType": "token",
        "currencyName": "Polygon (Matic) ERC20",
        "currencyCode": "ETH_MATIC",
        "currencySymbol": "MATIC",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "MATIC",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0",
        "currencyExplorerLink": "https://etherscan.io/token/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0?a=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "MATIC": {
        "currencyType": "coin",
        "currencyName": "Polygon (Matic) Network",
        "currencyCode": "MATIC",
        "currencySymbol": "MATIC",
        "ratesCurrencyCode": "MATIC",
        "addressProcessor": "ETH",
        "addressUiChecker": "ETH",
        "scannerProcessor": "ETH",
        "extendsProcessor": "ETH",
        "prettyNumberProcessor": "ETH",
        "transferProcessor": "ETC",
        "network": "mainnet",
        "decimals": 18,
        "currencyExplorerLink": "https://polygonscan.com/address/",
        "currencyExplorerTxLink": "https://polygonscan.com/tx/"
    },
    "MATIC_WMATIC": {
        "currencyType": "token",
        "currencyName": "Wrapped Matic (Polygon)",
        "currencyCode": "MATIC_WMATIC",
        "currencySymbol": "WMATIC",
        "ratesCurrencyCode": "MATIC",
        "extendsProcessor": "MATIC_USDT",
        "addressUiChecker": "ETH",
        "tokenAddress": "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
        "decimals": 18,
        "tokenBlockchain": "MATIC",
        "currencyExplorerLink": "https://polygonscan.com/token/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270?a="
    },
    "SOL_RAY": {
        "currencyType": "token",
        "transferProcessor": "SOL_SPL",
        "currencyName": "Raydium",
        "currencyCode": "SOL_RAY",
        "currencySymbol": "RAY",
        "ratesCurrencyCode": "RAY",
        "addressProcessor": "SOL",
        "scannerProcessor": "SOL_SPL",
        "prettyNumberProcessor": "UNIFIED",
        "addressCurrencyCode": "SOL",
        "addressUiChecker": "SOL",
        "feesCurrencyCode": "SOL",
        "network": "sol",
        "decimals": 6,
        "tokenBlockchain": "SOLANA",
        "tokenAddress": "4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R",
        "currencyExplorerLink": "https://explorer.solana.com/address/",
        "currencyExplorerTxLink": "https://explorer.solana.com/tx/"
    },
    "ETH_RSR": {
        "currencyType": "token",
        "currencyName": "Reserve Rights",
        "currencyCode": "ETH_RSR",
        "currencySymbol": "RSR",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "RSR",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x8762db106b2c2a0bccb3a80d1ed41273552616e8",
        "currencyExplorerLink": "https://etherscan.io/token/0x8762db106b2c2a0bccb3a80d1ed41273552616e8?a=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "XRP": {
        "currencyType": "coin",
        "currencyName": "Ripple",
        "currencyCode": "XRP",
        "currencySymbol": "XRP",
        "addressProcessor": "XRP",
        "scannerProcessor": "XRP",
        "extendsProcessor": "XRP",
        "prettyNumberProcessor": "USDT",
        "network": "mainnet",
        "decimals": 8,
        "currencyExplorerLink": "https://blockchair.com/ripple/account/",
        "currencyExplorerTxLink": "https://blockchair.com/ripple/transaction/"
    },
    "SOL_SBR": {
        "currencyType": "token",
        "currencyName": "Saber Protocol Token",
        "currencyCode": "SOL_SBR",
        "currencySymbol": "SBR",
        "extendsProcessor": "SOL_RAY",
        "addressUiChecker": "SOL",
        "ratesCurrencyCode": "SBR",
        "decimals": 6,
        "tokenBlockchain": "SOLANA",
        "tokenAddress": "Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1",
        "currencyExplorerLink": "https://explorer.solana.com/address/",
        "currencyExplorerTxLink": "https://explorer.solana.com/tx/"
    },
    "SOL_SRM": {
        "currencyType": "token",
        "currencyName": "Serum",
        "currencyCode": "SOL_SRM",
        "currencySymbol": "SRM",
        "extendsProcessor": "SOL_RAY",
        "addressUiChecker": "SOL",
        "ratesCurrencyCode": "SRM",
        "decimals": 6,
        "tokenBlockchain": "SOLANA",
        "tokenAddress": "SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt",
        "currencyExplorerLink": "https://explorer.solana.com/address/",
        "currencyExplorerTxLink": "https://explorer.solana.com/tx/"
    },
    "BNB_SMART_SHIB": {
        "currencyType": "token",
        "currencyName": "Shiba BEP20",
        "currencyCode": "BNB_SMART_SHIB",
        "currencySymbol": "SHIB",
        "extendsProcessor": "BNB_SMART_CAKE",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "SHIB",
        "tokenBlockchain": "BNB",
        "tokenAddress": "0x2859e4544c4bb03966803b044a93563bd2d0dd4d",
        "currencyExplorerLink": "https://bscscan.com/address/0x2859e4544c4bb03966803b044a93563bd2d0dd4d?a="
    },
    "ETH_SHIB": {
        "currencyType": "token",
        "currencyName": "Shiba ERC20",
        "currencyCode": "ETH_SHIB",
        "currencySymbol": "SHIB",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "SHIB",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
        "currencyExplorerLink": "https://etherscan.io/token/0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce?a=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "SOL": {
        "currencyType": "coin",
        "currencyName": "Solana",
        "currencyCode": "SOL",
        "currencySymbol": "SOL",
        "addressProcessor": "SOL",
        "addressUiChecker": "SOL",
        "scannerProcessor": "SOL",
        "prettyNumberProcessor": "UNIFIED",
        "network": "mainnet",
        "decimals": 9,
        "currencyExplorerLink": "https://explorer.solana.com/address/",
        "currencyExplorerTxLink": "https://explorer.solana.com/tx/"
    },
    "SOL_SLIM": {
        "currencyType": "token",
        "currencyName": "Solanium",
        "currencyCode": "SOL_SLIM",
        "currencySymbol": "SLIM",
        "extendsProcessor": "SOL_RAY",
        "addressUiChecker": "SOL",
        "ratesCurrencyCode": "SLIM",
        "decimals": 6,
        "tokenBlockchain": "SOLANA",
        "tokenAddress": "xxxxa1sKNGwFtw2kFn8XauW9xq8hBZ5kVtcSesTT9fW",
        "currencyExplorerLink": "https://explorer.solana.com/address/",
        "currencyExplorerTxLink": "https://explorer.solana.com/tx/"
    },
    "XLM": {
        "currencyType": "coin",
        "currencyName": "Stellar",
        "currencyCode": "XLM",
        "currencySymbol": "XLM",
        "addressProcessor": "XLM",
        "scannerProcessor": "XLM",
        "extendsProcessor": "XLM",
        "prettyNumberProcessor": "USDT",
        "network": "mainnet",
        "decimals": 8,
        "currencyExplorerLink": "https://blockchair.com/stellar/account/",
        "currencyExplorerTxLink": "https://blockchair.com/stellar/transaction/"
    },
    "SOL_STEP": {
        "currencyType": "token",
        "currencyName": "Step",
        "currencyCode": "SOL_STEP",
        "currencySymbol": "STEP",
        "extendsProcessor": "SOL_RAY",
        "addressUiChecker": "SOL",
        "ratesCurrencyCode": "STEP",
        "decimals": 9,
        "tokenBlockchain": "SOLANA",
        "tokenAddress": "StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT",
        "currencyExplorerLink": "https://explorer.solana.com/address/",
        "currencyExplorerTxLink": "https://explorer.solana.com/tx/"
    },
    "TRX_SUN_NEW": {
        "currencyType": "token",
        "currencyName": "SUN",
        "currencyCode": "TRX_SUN_NEW",
        "currencySymbol": "SUN",
        "extendsProcessor": "TRX_USDT",
        "addressUiChecker": "TRX",
        "ratesCurrencyCode": "SUN_NEW",
        "network": "trx",
        "decimals": 18,
        "tokenBlockchain": "TRON",
        "tokenName": "TSSMHYeV2uE9qYH95DqyoCuNCzEL1NvU3S",
        "currencyExplorerLink": "https://tronscan.org/#/address/",
        "currencyExplorerTxLink": "https://tronscan.org/#/transaction/"
    },
    "TRX_SUN": {
        "currencyType": "token",
        "currencyName": "SUN Old",
        "currencyCode": "TRX_SUN",
        "currencySymbol": "SUN",
        "extendsProcessor": "TRX_USDT",
        "addressUiChecker": "TRX",
        "ratesCurrencyCode": "SUN",
        "network": "trx",
        "decimals": 18,
        "tokenBlockchain": "TRON",
        "tokenName": "TKkeiboTkxXKJpbmVFbv4a8ov5rAfRDMf9",
        "currencyExplorerLink": "https://tronscan.org/#/address/",
        "currencyExplorerTxLink": "https://tronscan.org/#/transaction/"
    },
    "ETH_SUSHI": {
        "currencyType": "token",
        "currencyName": "Sushi Token",
        "currencyCode": "ETH_SUSHI",
        "currencySymbol": "SUSHI",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "SUSHI",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x6b3595068778dd592e39a122f4f5a5cf09c90fe2",
        "currencyExplorerLink": "https://etherscan.io/token/0x6b3595068778dd592e39a122f4f5a5cf09c90fe2?a=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "ETH_SXP": {
        "currencyType": "token",
        "currencyName": "Swipe ERC20",
        "currencyCode": "ETH_SXP",
        "currencySymbol": "SXP",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "SXP",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9",
        "currencyExplorerLink": "https://etherscan.io/token/0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9?a=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "BNB_SMART_SXP": {
        "currencyType": "token",
        "currencyName": "Swipe BEP20",
        "currencyCode": "BNB_SMART_SXP",
        "currencySymbol": "SXP",
        "ratesCurrencyCode": "SXP",
        "extendsProcessor": "BNB_SMART_CAKE",
        "addressUiChecker": "ETH",
        "tokenAddress": "0x47bead2563dcbf3bf2c9407fea4dc236faba485a",
        "tokenBlockchain": "BNB",
        "currencyExplorerLink": "https://bscscan.com/token/0x47bead2563dcbf3bf2c9407fea4dc236faba485a?a="
    },
    "SOL_SNY": {
        "currencyType": "token",
        "currencyName": "Synthetify",
        "currencyCode": "SOL_SNY",
        "currencySymbol": "SNY",
        "extendsProcessor": "SOL_RAY",
        "addressUiChecker": "SOL",
        "ratesCurrencyCode": "SNY",
        "decimals": 6,
        "tokenBlockchain": "SOLANA",
        "tokenAddress": "4dmKkXNHdgYsXqBHCuMikNQWwVomZURhYvkkX5c4pQ7y",
        "currencyExplorerLink": "https://explorer.solana.com/address/",
        "currencyExplorerTxLink": "https://explorer.solana.com/tx/"
    },
    "ETH_SNX": {
        "currencyType": "token",
        "currencyName": "Synthetix",
        "currencyCode": "ETH_SNX",
        "currencySymbol": "SNX",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "SNX",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f",
        "currencyExplorerLink": "https://etherscan.io/token/0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f?a=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "BNB_SMART_USDT": {
        "currencyType": "token",
        "currencyName": "Tether BEP20",
        "currencyCode": "BNB_SMART_USDT",
        "currencySymbol": "USDT (BSC-USD)",
        "ratesCurrencyCode": "USDT",
        "extendsProcessor": "BNB_SMART_CAKE",
        "addressUiChecker": "ETH",
        "tokenAddress": "0x55d398326f99059ff775485246999027b3197955",
        "tokenBlockchain": "BNB",
        "currencyExplorerLink": "https://bscscan.com/token/0x55d398326f99059ff775485246999027b3197955?a="
    },
    "BNB_SMART_TRX": {
        "currencyType": "token",
        "currencyName": "TRX BEP20",
        "currencyCode": "BNB_SMART_TRX",
        "currencySymbol": "TRX",
        "ratesCurrencyCode": "TRX",
        "extendsProcessor": "BNB_SMART_CAKE",
        "addressUiChecker": "ETH",
        "tokenAddress": "0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b",
        "tokenBlockchain": "BNB",
        "currencyExplorerLink": "https://bscscan.com/token/0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b?a="
    },
    "ETH_TRX": {
        "currencyType": "token",
        "currencyName": "TRX ERC20",
        "currencyCode": "ETH_TRX",
        "currencySymbol": "TRX",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "TRX",
        "decimals": 8,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0xe1be5d3f34e89de342ee97e6e90d405884da6c67",
        "currencyExplorerLink": "https://etherscan.io/token/0xe1be5d3f34e89de342ee97e6e90d405884da6c677?a="
    },
    "SOL_TULIP": {
        "currencyType": "token",
        "currencyName": "TULIP (Parrot)",
        "currencyCode": "SOL_TULIP",
        "currencySymbol": "TULIP",
        "extendsProcessor": "SOL_RAY",
        "addressUiChecker": "SOL",
        "ratesCurrencyCode": "TULIP",
        "decimals": 6,
        "tokenBlockchain": "SOLANA",
        "tokenAddress": "TuLipcqtGVXP9XR62wM8WWCm6a9vhLs7T1uoWBk6FDs",
        "currencyExplorerLink": "https://explorer.solana.com/address/",
        "currencyExplorerTxLink": "https://explorer.solana.com/tx/"
    },
    "BNB_SMART_UNI": {
        "currencyType": "token",
        "currencyName": "Uniswap BEP20",
        "currencyCode": "BNB_SMART_UNI",
        "currencySymbol": "UNI",
        "ratesCurrencyCode": "UNI",
        "extendsProcessor": "BNB_SMART_CAKE",
        "addressUiChecker": "ETH",
        "tokenAddress": "0xbf5140a22578168fd562dccf235e5d43a02ce9b1",
        "tokenBlockchain": "BNB",
        "currencyExplorerLink": "https://bscscan.com/token/0xbf5140a22578168fd562dccf235e5d43a02ce9b1?a="
    },
    "ETH_UNI": {
        "currencyType": "token",
        "currencyName": "Uniswap ERC20",
        "currencyCode": "ETH_UNI",
        "currencySymbol": "UNI",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "UNI",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
        "currencyExplorerLink": "https://etherscan.io/token/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984?a=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "BNB_SMART_USD": {
        "currencyType": "token",
        "currencyName": "USD BEP20",
        "currencyCode": "BNB_SMART_USD",
        "currencySymbol": "BUSD",
        "ratesCurrencyCode": "BUSD",
        "extendsProcessor": "BNB_SMART_CAKE",
        "addressUiChecker": "ETH",
        "tokenAddress": "0xe9e7cea3dedca5984780bafc599bd69add087d56",
        "tokenBlockchain": "BNB",
        "currencyExplorerLink": "https://bscscan.com/token/0xe9e7cea3dedca5984780bafc599bd69add087d56?a="
    },
    "BNB_SMART_USDC": {
        "currencyType": "token",
        "currencyName": "USDC BEP20",
        "currencyCode": "BNB_SMART_USDC",
        "currencySymbol": "USDC",
        "ratesCurrencyCode": "USDC",
        "extendsProcessor": "BNB_SMART_CAKE",
        "addressUiChecker": "ETH",
        "tokenAddress": "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
        "tokenBlockchain": "BNB",
        "currencyExplorerLink": "https://bscscan.com/token/0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d?a="
    },
    "MATIC_USDC": {
        "currencyType": "token",
        "currencyName": "USDC Matic (Polygon)",
        "currencyCode": "MATIC_USDC",
        "currencySymbol": "USDC",
        "ratesCurrencyCode": "USDC",
        "extendsProcessor": "MATIC_USDT",
        "addressUiChecker": "ETH",
        "tokenAddress": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
        "decimals": 6,
        "tokenBlockchain": "MATIC",
        "currencyExplorerLink": "https://polygonscan.com/token/0x2791bca1f2de4661ed88a30c99a7a9449aa84174?a="
    },
    "FTM_USDC": {
        "currencyType": "token",
        "currencyName": "USDC Fantom",
        "currencyCode": "FTM_USDC",
        "currencyIcon": "USDC",
        "currencySymbol": "USDC",
        "addressProcessor": "ETH",
        "scannerProcessor": "ETH_ERC_20",
        "transferProcessor": "BNB_SMART_20",
        "prettyNumberProcessor": "ETH_ERC_20",
        "addressCurrencyCode": "ETH",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "USDC",
        "feesCurrencyCode": "FTM",
        "network": "mainnet",
        "decimals": 6,
        "tokenBlockchain": "FTM",
        "tokenAddress": "0x04068da6c83afcfa0e13ba15a6696662335d5b75",
        "currencyExplorerLink": "https://polygonscan.com/token/0x04068da6c83afcfa0e13ba15a6696662335d5b75?a=",
        "currencyExplorerTxLink": "https://polygonscan.com/tx/"
    },
    "SOL_USDC": {
        "currencyType": "token",
        "currencyName": "USDC Solana",
        "currencyCode": "SOL_USDC",
        "currencySymbol": "USDC",
        "extendsProcessor": "SOL_RAY",
        "addressUiChecker": "SOL",
        "ratesCurrencyCode": "USDC",
        "decimals": 6,
        "tokenBlockchain": "SOLANA",
        "tokenAddress": "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
        "currencyExplorerLink": "https://explorer.solana.com/address/",
        "currencyExplorerTxLink": "https://explorer.solana.com/tx/"
    },
    "MATIC_USDT": {
        "currencyType": "token",
        "currencyName": "USDT Matic (Polygon)",
        "currencyCode": "MATIC_USDT",
        "currencyIcon": "USDT",
        "currencySymbol": "USDT",
        "addressProcessor": "ETH",
        "scannerProcessor": "ETH_ERC_20",
        "transferProcessor": "BNB_SMART_20",
        "prettyNumberProcessor": "ETH_ERC_20",
        "addressCurrencyCode": "ETH",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "USDT",
        "feesCurrencyCode": "MATIC",
        "network": "mainnet",
        "decimals": 6,
        "tokenBlockchain": "MATIC",
        "tokenAddress": "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
        "currencyExplorerLink": "https://polygonscan.com/token/0xc2132d05d31c914a87c6611c10748aeb04b58e8f?a=",
        "currencyExplorerTxLink": "https://polygonscan.com/tx/"
    },
    "SOL_USDT": {
        "currencyType": "token",
        "currencyName": "USDT Solana",
        "currencyCode": "SOL_USDT",
        "currencySymbol": "USDT",
        "extendsProcessor": "SOL_RAY",
        "addressUiChecker": "SOL",
        "ratesCurrencyCode": "USDT",
        "decimals": 6,
        "tokenBlockchain": "SOLANA",
        "tokenAddress": "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB",
        "currencyExplorerLink": "https://explorer.solana.com/address/",
        "currencyExplorerTxLink": "https://explorer.solana.com/tx/"
    },
    "VET": {
        "currencyType": "coin",
        "currencyName": "VeChain Thor",
        "currencyCode": "VET",
        "currencySymbol": "VET",
        "ratesCurrencyCode": "VET",
        "addressCurrencyCode": "VET",
        "addressProcessor": "ETH",
        "addressUiChecker": "ETH",
        "scannerProcessor": "VET",
        "prettyNumberProcessor": "ETH",
        "transferProcessor": "VET",
        "network": "mainnet",
        "decimals": 18,
        "currencyExplorerLink": "https://explore.vechain.org/accounts/",
        "currencyExplorerTxLink": "https://explore.vechain.org/transactions/"
    },
    "VTHO": {
        "currencyType": "coin",
        "currencyName": "VeChain Thor Token",
        "currencyCode": "VTHO",
        "currencySymbol": "VTHO",
        "ratesCurrencyCode": "VTHO",
        "addressCurrencyCode": "VET",
        "addressProcessor": "ETH",
        "addressUiChecker": "ETH",
        "scannerProcessor": "VET",
        "prettyNumberProcessor": "ETH",
        "transferProcessor": "VET",
        "network": "mainnet",
        "decimals": 18,
        "currencyExplorerLink": "https://explore.vechain.org/accounts/",
        "currencyExplorerTxLink": "https://explore.vechain.org/transactions/"
    },
    "XVG": {
        "currencyType": "coin",
        "currencyName": "Verge",
        "currencyCode": "XVG",
        "currencySymbol": "XVG",
        "extendsProcessor": "BTC",
        "scannerProcessor": "XVG",
        "addressUiChecker": "BTC_BY_NETWORK",
        "rateUiScanner": "XVG",
        "network": "verge",
        "prettyNumberProcessor": "UNIFIED",
        "decimals": 6,
        "currencyExplorerLink": "https://verge-blockchain.info/address/",
        "currencyExplorerTxLink": "https://verge-blockchain.info/tx/"
    },
    "WAVES": {
        "currencyType": "coin",
        "currencyName": "Waves",
        "currencyCode": "WAVES",
        "currencySymbol": "WAVES",
        "addressProcessor": "WAVES",
        "scannerProcessor": "WAVES",
        "prettyNumberProcessor": "UNIFIED",
        "decimals": 8,
        "currencyExplorerLink": "https://wavesexplorer.com/address/",
        "currencyExplorerTxLink": "https://wavesexplorer.com/tx/"
    },
    "TRX_WINK": {
        "currencyType": "token",
        "currencyName": "WIN Token",
        "currencyCode": "TRX_WINK",
        "currencySymbol": "WINK",
        "extendsProcessor": "TRX_USDT",
        "addressUiChecker": "TRX",
        "ratesCurrencyCode": "WIN",
        "network": "trx",
        "decimals": 6,
        "tokenBlockchain": "TRON",
        "tokenName": "TLa2f6VPqDgRE67v1736s7bJ8Ray5wYjU7",
        "currencyExplorerLink": "https://tronscan.org/#/address/",
        "currencyExplorerTxLink": "https://tronscan.org/#/transaction/"
    },
    "BNB_SMART_WINK": {
        "currencyType": "token",
        "currencyName": "WINK BEP20",
        "currencyCode": "BNB_SMART_WINK",
        "currencySymbol": "WINK",
        "ratesCurrencyCode": "WINK",
        "extendsProcessor": "BNB_SMART_CAKE",
        "addressUiChecker": "ETH",
        "tokenAddress": "0xaef0d72a118ce24fee3cd1d43d383897d05b4e99",
        "tokenBlockchain": "BNB",
        "currencyExplorerLink": "https://bscscan.com/token/0xaef0d72a118ce24fee3cd1d43d383897d05b4e99?a="
    },
    "ETH_BTC": {
        "currencyType": "token",
        "currencyName": "Wrapped BTC ERC20",
        "currencyCode": "ETH_BTC",
        "currencySymbol": "BTC",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "BTC",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
        "currencyExplorerLink": "https://etherscan.io/token/0x2260fac5e5542a773aa44fbcfedf7c193bc2c599?a=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "ETH_YFI": {
        "currencyType": "token",
        "currencyName": "yearn.finance",
        "currencyCode": "ETH_YFI",
        "currencySymbol": "YFI",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "YFI",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e",
        "currencyExplorerLink": "https://etherscan.io/token/0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e?a=",
        "currencyExplorerTxLink": "https://etherscan.io/tx/"
    },
    "TRX_EXON": {
        "currencyType": "token",
        "currencyName": "Exon Center",
        "currencyCode": "TRX_EXON",
        "currencySymbol": "EXON",
        "extendsProcessor": "TRX_USDT",
        "addressUiChecker": "TRX",
        "ratesCurrencyCode": "EXON",
        "network": "trx",
        "decimals": 6,
        "tokenBlockchain": "TRON",
        "tokenName": "TRpfGv87u8tmdNW9rst5bpw6N7aEAE94Uh",
        "currencyExplorerLink": "https://tronscan.org/#/address/",
        "currencyExplorerTxLink": "https://tronscan.org/#/transaction/"
    },
    "NFT": {
        "currencyType": "special",
        "currencyName": "NFT",
        "currencyCode": "NFT",
        "currencySymbol": "NFT",
        "isHidden": false
    },
    "CASHBACK": {
        "currencyType": "special",
        "currencyName": "Cashback",
        "currencyCode": "CASHBACK",
        "currencySymbol": "USDT",
        "isHidden": false
    },
    "METIS": {
        "currencyType": "coin",
        "currencyName": "Metis",
        "currencyCode": "METIS",
        "currencySymbol": "METIS",
        "ratesCurrencyCode": "METIS",
        "addressCurrencyCode": "ETH",
        "addressProcessor": "ETH",
        "addressUiChecker": "ETH",
        "scannerProcessor": "METIS",
        "extendsProcessor": "ETH",
        "prettyNumberProcessor": "ETH",
        "transferProcessor": "METIS",
        "network": "mainnet",
        "decimals": 18,
        "currencyExplorerLink": "https://andromeda-explorer.metis.io/address/",
        "currencyExplorerTxLink": "https://andromeda-explorer.metis.io/tx/"
    },
    "METIS_METIS": {
        "currencyType": "token",
        "currencyName": "Wrapped Metis (METIS)",
        "currencyCode": "METIS_METIS",
        "currencyIcon": "METIS",
        "currencySymbol": "METIS",
        "addressProcessor": "ETH",
        "scannerProcessor": "ETH_ERC_20",
        "transferProcessor": "BNB_SMART_20",
        "prettyNumberProcessor": "ETH_ERC_20",
        "addressCurrencyCode": "ETH",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "METIS",
        "feesCurrencyCode": "METIS",
        "network": "mainnet",
        "decimals": 18,
        "tokenBlockchain": "METIS",
        "tokenAddress": "0x75cb093E4D61d2A2e65D8e0BBb01DE8d89b53481",
        "currencyExplorerLink": "https://andromeda-explorer.metis.io/address/",
        "currencyExplorerTxLink": "https://andromeda-explorer.metis.io/tx/"
    },
    "ETH_METIS": {
        "currencyType": "token",
        "currencyName": "Metis ERC20",
        "currencyCode": "ETH_METIS",
        "currencySymbol": "METIS",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "METIS",
        "decimals": 18,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0x9E32b13ce7f2E80A01932B42553652E053D6ed8e",
        "currencyExplorerLink": "https://etherscan.io/token/0x9E32b13ce7f2E80A01932B42553652E053D6ed8e?a="
    },
    "BNB_SMART_METIS": {
        "currencyType": "token",
        "currencyName": "Metis BEP20",
        "currencyCode": "BNB_SMART_METIS",
        "currencySymbol": "METIS",
        "ratesCurrencyCode": "METIS",
        "extendsProcessor": "BNB_SMART_CAKE",
        "addressUiChecker": "ETH",
        "tokenAddress": "0xe552fb52a4f19e44ef5a967632dbc320b0820639",
        "tokenBlockchain": "BNB",
        "currencyExplorerLink": "https://bscscan.com/token/0xe552fb52a4f19e44ef5a967632dbc320b0820639?a="
    },
    "ETH_STORJ": {
        "currencyType": "token",
        "currencyName": "STORJ ERC20",
        "currencyCode": "ETH_STORJ",
        "currencySymbol": "STORJ",
        "extendsProcessor": "ETH_TRUE_USD",
        "addressUiChecker": "ETH",
        "ratesCurrencyCode": "STORJ",
        "decimals": 8,
        "tokenBlockchain": "ETHEREUM",
        "tokenAddress": "0xb64ef51c888972c908cfacf59b47c1afbc0ab8ac",
        "currencyExplorerLink": "https://etherscan.io/token/0xb64ef51c888972c908cfacf59b47c1afbc0ab8ac?a="
    },
    "VLX": {
        "currencyType": "coin",
        "currencyName": "Velas",
        "currencyCode": "VLX",
        "currencySymbol": "VLX",
        "ratesCurrencyCode": "VLX",
        "addressCurrencyCode": "ETH",
        "addressProcessor": "ETH",
        "addressUiChecker": "ETH",
        "scannerProcessor": "ETH",
        "extendsProcessor": "ETH",
        "prettyNumberProcessor": "ETH",
        "transferProcessor": "ETC",
        "network": "mainnet",
        "decimals": 18,
        "currencyExplorerLink": "https://explorer.velas.com/address/",
        "currencyExplorerTxLink": "https://explorer.velas.com/tx/"
    },
    "ETH_UFI": {
		"currencyType": "token",
		"currencyName": "PureFi Token ERC20",
		"currencyCode": "ETH_UFI",
		"currencySymbol": "UFI",
		"extendsProcessor": "ETH_TRUE_USD",
		"addressUiChecker": "ETH",
		"ratesCurrencyCode": "PUREFI",
		"decimals": 18,
		"tokenBlockchain": "ETHEREUM",
		"tokenAddress": "0xcDa4e840411C00a614aD9205CAEC807c7458a0E3",
		"currencyExplorerLink": "https://etherscan.io/token/0xcDa4e840411C00a614aD9205CAEC807c7458a0E3?a="
	},
	"BNB_SMART_UFI": {
		"currencyType": "token",
		"currencyName": "PureFi Token BEP20",
		"currencyCode": "BNB_SMART_UFI",
		"currencySymbol": "UFI",
		"ratesCurrencyCode": "PUREFI",
		"extendsProcessor": "BNB_SMART_CAKE",
		"addressUiChecker": "ETH",
		"tokenAddress": "0xe2a59D5E33c6540E18aAA46BF98917aC3158Db0D",
		"tokenBlockchain": "BNB",
		"currencyExplorerLink": "https://bscscan.com/token/0xe2a59D5E33c6540E18aAA46BF98917aC3158Db0D?a="
	},
	"ETH_CLOUD": {
		"currencyType": "token",
		"currencyName": "Cloud NFT ERC20",
		"currencyCode": "ETH_CLOUD",
		"currencySymbol": "CLOUD",
		"extendsProcessor": "ETH_TRUE_USD",
		"addressUiChecker": "ETH",
		"ratesCurrencyCode": "CLOUD",
		"decimals": 18,
		"tokenBlockchain": "ETHEREUM",
		"tokenAddress": "0x810B56F6eC21eb5d24FcEd5c104A7dCa56f95e5d",
		"currencyExplorerLink": "https://etherscan.io/token/0x810B56F6eC21eb5d24FcEd5c104A7dCa56f95e5d?a="
	},
	"BNB_SMART_CLOUD": {
		"currencyType": "token",
		"currencyName": "Cloud NFT BEP20",
		"currencyCode": "BNB_SMART_CLOUD",
		"currencySymbol": "CLOUD",
		"ratesCurrencyCode": "CLOUD",
		"extendsProcessor": "BNB_SMART_CAKE",
		"addressUiChecker": "ETH",
		"tokenAddress": "0x810B56F6eC21eb5d24FcEd5c104A7dCa56f95e5d",
		"tokenBlockchain": "BNB",
		"currencyExplorerLink": "https://bscscan.com/token/0x810B56F6eC21eb5d24FcEd5c104A7dCa56f95e5d?a="
	},
	"ETH_HEX": {
		"currencyType": "token",
		"currencyName": "HEX ERC20",
		"currencyCode": "ETH_HEX",
		"currencySymbol": "HEX",
		"extendsProcessor": "ETH_TRUE_USD",
		"addressUiChecker": "ETH",
		"ratesCurrencyCode": "HEX",
		"decimals": 8,
		"tokenBlockchain": "ETHEREUM",
		"tokenAddress": "0x2b591e99afe9f32eaa6214f7b7629768c40eeb39",
		"currencyExplorerLink": "https://etherscan.io/token/0x2b591e99afe9f32eaa6214f7b7629768c40eeb39?a="
	},
	"ETH_PAXG": {
		"currencyType": "token",
		"currencyName": "PAX Gold ERC20",
		"currencyCode": "ETH_PAXG",
		"currencySymbol": "PAXG",
		"extendsProcessor": "ETH_TRUE_USD",
		"addressUiChecker": "ETH",
		"ratesCurrencyCode": "PAXG",
		"decimals": 18,
		"tokenBlockchain": "ETHEREUM",
		"tokenAddress": "0x45804880de22913dafe09f4980848ece6ecbaf78",
		"currencyExplorerLink": "https://etherscan.io/token/0x45804880de22913dafe09f4980848ece6ecbaf78?a="
	},
	"ETH_ANT": {
		"currencyType": "token",
		"currencyName": "Aragon ERC20",
		"currencyCode": "ETH_ANT",
		"currencySymbol": "ANT",
		"extendsProcessor": "ETH_TRUE_USD",
		"addressUiChecker": "ETH",
		"ratesCurrencyCode": "ANT",
		"decimals": 18,
		"tokenBlockchain": "ETHEREUM",
		"tokenAddress": "0xa117000000f279d81a1d3cc75430faa017fa5a2e",
		"currencyExplorerLink": "https://etherscan.io/token/0xa117000000f279d81a1d3cc75430faa017fa5a2e?a="
	},
	"ETH_COTI": {
		"currencyType": "token",
		"currencyName": "COTI ERC20",
		"currencyCode": "ETH_COTI",
		"currencySymbol": "COTI",
		"extendsProcessor": "ETH_TRUE_USD",
		"addressUiChecker": "ETH",
		"ratesCurrencyCode": "COTI",
		"decimals": 18,
		"tokenBlockchain": "ETHEREUM",
		"tokenAddress": "0xDDB3422497E61e13543BeA06989C0789117555c5",
		"currencyExplorerLink": "https://etherscan.io/token/0xDDB3422497E61e13543BeA06989C0789117555c5?a="
	},
	"BNB_SMART_C98": {
		"currencyType": "token",
		"currencyName": "Coin98 BEP20",
		"currencyCode": "BNB_SMART_C98",
		"currencySymbol": "C98",
		"ratesCurrencyCode": "COIN98",
		"extendsProcessor": "BNB_SMART_CAKE",
		"addressUiChecker": "ETH",
		"tokenAddress": "0xaec945e04baf28b135fa7c640f624f8d90f1c3a6",
		"tokenBlockchain": "BNB",
		"currencyExplorerLink": "https://bscscan.com/token/0xaec945e04baf28b135fa7c640f624f8d90f1c3a6?a="
	},
	"ETH_C98": {
		"currencyType": "token",
		"currencyName": "Coin98 ERC20",
		"currencyCode": "ETH_C98",
		"currencySymbol": "C98",
		"extendsProcessor": "ETH_TRUE_USD",
		"addressUiChecker": "ETH",
		"ratesCurrencyCode": "COIN98",
		"decimals": 18,
		"tokenBlockchain": "ETHEREUM",
		"tokenAddress": "0xae12c5930881c53715b369cec7606b70d8eb229f",
		"currencyExplorerLink": "https://etherscan.io/token/0xae12c5930881c53715b369cec7606b70d8eb229f?a="
	},
	"ETH_REN": {
		"currencyType": "token",
		"currencyName": "Ren ERC20",
		"currencyCode": "ETH_REN",
		"currencySymbol": "REN",
		"extendsProcessor": "ETH_TRUE_USD",
		"addressUiChecker": "ETH",
		"ratesCurrencyCode": "REN",
		"decimals": 18,
		"tokenBlockchain": "ETHEREUM",
		"tokenAddress": "0x408e41876cccdc0f92210600ef50372656052a38",
		"currencyExplorerLink": "https://etherscan.io/token/0x408e41876cccdc0f92210600ef50372656052a38?a="
	},
	"ETH_CVC": {
		"currencyType": "token",
		"currencyName": "Civic ERC20",
		"currencyCode": "ETH_CVC",
		"currencySymbol": "CVC",
		"extendsProcessor": "ETH_TRUE_USD",
		"addressUiChecker": "ETH",
		"ratesCurrencyCode": "CVC",
		"decimals": 8,
		"tokenBlockchain": "ETHEREUM",
		"tokenAddress": "0x41e5560054824ea6b0732e656e3ad64e20e94e45",
		"currencyExplorerLink": "https://etherscan.io/token/0x41e5560054824ea6b0732e656e3ad64e20e94e45?a="
	},
	"ETH_DENT": {
		"currencyType": "token",
		"currencyName": "Dent ERC20",
		"currencyCode": "ETH_DENT",
		"currencySymbol": "DENT",
		"extendsProcessor": "ETH_TRUE_USD",
		"addressUiChecker": "ETH",
		"ratesCurrencyCode": "DENT",
		"decimals": 8,
		"tokenBlockchain": "ETHEREUM",
		"tokenAddress": "0x3597bfd533a99c9aa083587b074434e61eb0a258",
		"currencyExplorerLink": "https://etherscan.io/token/0x3597bfd533a99c9aa083587b074434e61eb0a258?a="
	},
	"ETH_GNO": {
		"currencyType": "token",
		"currencyName": "Gnosis ERC20",
		"currencyCode": "ETH_GNO",
		"currencySymbol": "GNO",
		"extendsProcessor": "ETH_TRUE_USD",
		"addressUiChecker": "ETH",
		"ratesCurrencyCode": "GNO",
		"decimals": 18,
		"tokenBlockchain": "ETHEREUM",
		"tokenAddress": "0x6810e776880c02933d47db1b9fc05908e5386b96",
		"currencyExplorerLink": "https://etherscan.io/token/0x6810e776880c02933d47db1b9fc05908e5386b96?a="
	},
	"ETH_BRD": {
		"currencyType": "token",
		"currencyName": "Bread ERC20",
		"currencyCode": "ETH_BRD",
		"currencySymbol": "BRD",
		"extendsProcessor": "ETH_TRUE_USD",
		"addressUiChecker": "ETH",
		"ratesCurrencyCode": "BRD",
		"decimals": 18,
		"tokenBlockchain": "ETHEREUM",
		"tokenAddress": "0x558ec3152e2eb2174905cd19aea4e34a23de9ad6",
		"currencyExplorerLink": "https://etherscan.io/token/0x558ec3152e2eb2174905cd19aea4e34a23de9ad6?a="
	}
}

export default CryptoCurrencies